<template>
  <div class="v-stack h-stretch gap-3">
    <button v-if="!id || id == $store.state.id || $store.state.accessLevel > 9" class="add"
      @click="$router.push('/finance/loans/add/' + (id ? id : ''))">
      Take a Loan
    </button>
    <div class="h-stack v-stretch h-space-between gap-3">
      <a class="heading-title-2">Loans</a>
      <div class="h-stack gap-3">
        <label style="align-self: center">Created:</label>
        <input type="checkbox" v-model="created" />
        <label style="align-self: center">Signed:</label>
        <input type="checkbox" v-model="signed" />
        <label style="align-self: center">Canceled:</label>
        <input type="checkbox" v-model="canceled" />
      </div>
    </div>
    <Table :items="filteredLoans" :defaultColumn="0" v-on:rowSelected="$router.push('/loans/detail/' + $event._id)"
      css="80px 100px 100px 100px 100px 1fr 60px" :columns="[
        {
          name: 'Date',
          sort: 'Date',
          path: ['startDate'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Amount',
          sort: 'Numeric',
          path: ['amount'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Paid',
          sort: 'Numeric',
          path: ['paid'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Left To Pay',
          sort: 'Numeric',
          path: ['leftToPay'],
          format: 'czk',
          formatType: 'currency',
          styleFunction: (item) => {
            if (item.state == 'signed') {
              if (item.leftToPay > 0) {
                return 'color: red;';
              } else {
                return 'color: green;';
              }
            }
            return '';
          },
        },
        {
          name: 'Monthly',
          sort: 'Numeric',
          path: ['monthlyRate'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Description',
          sort: 'Alphabet',
          path: ['description'],
        },
        {
          name: 'State',
          sort: 'Alphabet',
          path: ['state'],
        },
      ]"></Table>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import { mapActions } from "vuex";
import { loanObject } from "@/utils.js";

export default {
  data() {
    return {
      loans: [],
      signed: true,
      canceled: false,
      created: true,
    };
  },
  props: ["id"],
  components: {
    Table,
  },
  methods: {
    ...mapActions(["getUserLoans", "getLoans"]),
  },
  computed: {
    filteredLoans() {
      let loans = this.loans;

      if (!this.signed) {
        loans = loans.filter((loan) => loan.state != "signed");
      }

      if (!this.created) {
        loans = loans.filter((loan) => loan.state != "created");
      }

      if (!this.canceled) {
        loans = loans.filter((loan) => loan.state != "canceled");
      }

      return loans;
    },
  },
  mounted() {
    if (this.id && this.id != this.$store.state.id) {
      this.getLoans()
        .then((loans) => {
          this.loans = loans
            .filter((loan) => loan.user._id == this.id)
            .map((loan) => {
              loan.paid = loanObject.amountPaid(loan);
              loan.leftToPay = loanObject.leftToPay(loan);
              return loan;
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.getUserLoans()
        .then((loans) => {
          this.loans = loans.map((loan) => {
            loan.paid = loanObject.amountPaid(loan);
            loan.leftToPay = loanObject.leftToPay(loan);
            return loan;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>
